import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import RootRef from '@material-ui/core/RootRef'
import Select from '@material-ui/core/Select'
import React, { useEffect } from 'react'
import * as PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { FilledInput } from '@material-ui/core'

const SelectAssetField = ({ value, onChange, options }) => {
  const [labelWidth, setLabelWidth] = React.useState(0)
  const inputLabel = React.useRef(null)
  const intl = useIntl()

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  const handleFormFieldChange = ({ target: { name, value } }) => {
    onChange({ name, value })
  }

  return (
    <FormControl variant="outlined" fullWidth>
      <RootRef rootRef={inputLabel}>
        <InputLabel htmlFor="select-asset" variant="filled">
          {intl.formatMessage({ id: 'general.asset' })}
        </InputLabel>
      </RootRef>
      <Select
        value={value || ''}
        variant="filled"
        onChange={handleFormFieldChange}
        input={
          <FilledInput
            variant="filled"
            labelWidth={labelWidth}
            name="assetId"
            id="select-asset"
          />
        }
      >
        {options.map(({ id, name }) => (
          <MenuItem key={id} value={id} variant="filled">
            {name}
            {intl.formatMessage({ id: 'general.asset' })}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

SelectAssetField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
}

export default SelectAssetField
