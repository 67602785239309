import { makeStyles } from '@material-ui/styles'
import React from 'react'
import Layout from './layout/layout'

const useStyles = makeStyles({
  '@global': {
    body: {
      fontFamily: '"Open Sans", "Tahoma", "Arial", sans-serif;',
    },
    '#root': {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(0.5)',
      },
      '100%': {
        transform: 'scale(1)',
      },
    },
  },
})

const App = () => {
  useStyles()
  return <Layout />
}

export default App
