import React from 'react'
import { useIntl } from 'react-intl'
import { Redirect } from 'react-router'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/styles/makeStyles'
import { ReactComponent as AccountIcon } from '../../../resources/icons/Account.svg'
import { ReactComponent as PubKeyIcon } from '../../../resources/icons/PubKey.svg'
import { ReactComponent as ChainIcon } from '../../../resources/icons/Chain.svg'
import AuthorizeFormItem from './authorize-form-item'
import AppImage from '../../linked-apps/components/app-card/app-image'
import {
  Account,
  SingleSignatureAuthDescriptor,
  FlagsType,
  Operation,
} from 'ft3-lib'
import WalletButton from '../../../material/button'
import { loginCustodialWallet, getSignedTx } from '../../../api/api'
import DirectoryService from '../../../lib/directory-service'
import { generateEncMsgForPublicKey } from '../../../lib/utils'

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    margin: 'auto',
    maxWidth: 600,
  },
  innerWrapper: {
    margin: '30px auto',
    padding: 30,
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: 6,
    boxShadow: '0 1px 0 #bdcbd9',
  },
  logo: {
    height: 60,
    width: 60,
  },
  title: {
    marginTop: theme.spacing.unit,
    fontFamily: theme.typography.titleFont,
  },
  imageContainer: {
    marginRight: '20px',
    padding: 0,
  },
  dappName: {
    fontSize: '20px',
    marginBottom: 0,
    fontFamily: theme.typography.titleFont,
  },
  dappText: {
    lineHeight: 1.5,
    fontSize: '0.9rem',
    opacity: 0.5,
  },
  actions: {
    display: 'flex',
  },
  button: {
    flex: '1 0 50%',
  },
}))

const AuthorizeForm = ({
  dappSession,
  dappAccount,
  dappId,
  vaultAccount,
  pubkey,
  successAction,
  cancelAction,
}) => {
  const [redirectLocation, setRedirectLocation] = React.useState()
  const [actionStatus, setActionStatus] = React.useState(null)
  const [busy, setBusy] = React.useState(false)
  const [error, setError] = React.useState('')
  const classes = useStyles()
  const intl = useIntl()
  const dappConfig = new DirectoryService().getChainConfig(dappId)

  const handleAction = (action, rawTx) => {
    if (!action || action === '' || action === 'close') {
      window.close()
    } else if (/^[a-zA-Z_-]+:\/\//.test(action)) {
      const url = rawTx ? `${action}?rawTx=${rawTx}` : action
      window.location.replace(url)
    } else if (/^\//.test(action)) {
      setRedirectLocation(action)
    } else {
      throw new Error('Unknown action')
    }
  }

  const authorizeWithDAppInfo = async () => {
    const dappToAuth = new DirectoryService().getChainConnectionInfo(dappId)
    const encMsg = generateEncMsgForPublicKey(vaultAccount.userAuthHash, pubkey)

    const loginResp = await loginCustodialWallet({
      name: vaultAccount.name,
      encMsg,
      dappInfo: {
        blockchainUrl: dappToAuth.url,
        blockchainRID: dappId,
      },
    })

    if (!loginResp.ok) {
      throw new Error('Error trying custodial authenticate')
    }

    const jsonResp = await loginResp.json()
    return jsonResp.addClientAuthDescriptorRawTx.toUpperCase()
  }

  const authorize = async () => {
    const authDescriptor = new SingleSignatureAuthDescriptor(
      pubkey.toBuffer(),
      [FlagsType.Transfer],
    )

    setBusy(true)
    setError('')

    try {
      var proceedCallback = txn => {
        setActionStatus(intl.formatMessage({ id: 'authorize.submit.status' }))
        handleAction(successAction, txn)
      }

      if (vaultAccount.custodialLogin) {
        proceedCallback(await authorizeWithDAppInfo())
      } else {
        let rawTx

        if (!dappAccount) {
          var signers = [
            dappSession.user.authDescriptor.signers,
            authDescriptor.signers,
          ].flat()
          signers.push(
            '03c3557c33f2b7d69a72f72893146d2b278b1b9ec53b258ffae5b4346caa5b09d3'.toBuffer(),
          )
          rawTx = dappSession.blockchain
            .transactionBuilder()
            .add(
              new Operation(
                'ft3.dev_register_account',
                dappSession.user.authDescriptor,
              ),
            )
            .add(
              new Operation(
                'ft3.add_auth_descriptor',
                dappSession.user.authDescriptor.id,
                dappSession.user.authDescriptor.id,
                authDescriptor,
              ),
            )
            .build(signers)
            .sign(dappSession.user.keyPair)
            .raw()
            .toString('hex')

          const data = await getSignedTx(rawTx)
          const json = await data.json()
          rawTx = json.tx
        } else {
          rawTx = Account.rawTransactionAddAuthDescriptor(
            dappAccount.id,
            dappSession.user,
            authDescriptor,
            dappSession.blockchain,
          ).toHex()
        }

        proceedCallback(rawTx)
      }
    } catch (error) {
      setError(intl.formatMessage({ id: 'authorize.error.failed' }))
    } finally {
      setBusy(false)
    }
  }

  const handleCancelClick = () => {
    setActionStatus(intl.formatMessage({ id: 'authorize.cancel.status' }))
    handleAction(cancelAction || successAction)
  }

  return (
    <div className={classes.wrapper}>
      {redirectLocation && <Redirect to={redirectLocation} />}
      <Typography variant="h3" paragraph className={classes.title}>
        {intl.formatMessage(
          { id: 'authorize.title' },
          { dappName: dappSession && dappSession.blockchain.info.name },
        )}
      </Typography>
      <div className={classes.innerWrapper}>
        <Grid container direction="column" spacing={32}>
          <Grid item container alignItems="center" spacing={8}>
            <Grid item xs="auto" className={classes.imageContainer}>
              <AppImage
                appId={dappSession && dappSession.blockchain.id.toHex()}
                appIcon={dappConfig.appIcon}
                userId={vaultAccount && vaultAccount.id}
                appSize={120}
                userSize={45}
              />
            </Grid>
            <Grid item>
              <Typography variant="h3" paragraph className={classes.dappName}>
                {dappSession && dappSession.blockchain.info.name}
              </Typography>
              <Typography
                variant="subtitle1"
                paragraph
                className={classes.dappText}
              >
                Corite
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs>
            <Typography variant="subtitle1" paragraph>
              {intl.formatMessage(
                { id: 'authorize.subtitle' },
                { dappName: dappSession && dappSession.blockchain.info.name },
              )}
            </Typography>
          </Grid>
          <Grid item xs="auto" container direction="column" spacing={24}>
            <AuthorizeFormItem
              title={intl.formatMessage({ id: 'general.application' })}
              text={dappSession && dappSession.blockchain.info.name}
              Icon={AccountIcon}
            />
            <AuthorizeFormItem
              title={intl.formatMessage(
                { id: 'general.id' },
                { field: intl.formatMessage({ id: 'general.account' }) },
              )}
              text={vaultAccount.id}
              Icon={PubKeyIcon}
            />
            <AuthorizeFormItem
              title={intl.formatMessage(
                { id: 'general.id' },
                { field: intl.formatMessage({ id: 'general.chain' }) },
              )}
              text={dappSession && dappSession.blockchain.id.toHex()}
              Icon={ChainIcon}
            />
            {error && (
              <Grid item>
                <Typography variant="subtitle1" color="error" paragraph>
                  {error}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs="auto"
            container
            direction="row"
            alignItems="center"
            spacing={8}
          >
            {actionStatus ? (
              <Grid item xs>
                <Typography variant="h5" paragraph>
                  {actionStatus}
                </Typography>
              </Grid>
            ) : (
              <>
                <Grid item xs>
                  <div className={classes.actions}>
                    <WalletButton
                      className={classes.button}
                      busy={busy}
                      onClick={authorize}
                    >
                      {intl.formatMessage({ id: 'authorize.submit' })}
                    </WalletButton>
                  </div>
                </Grid>
                <Grid item xs>
                  <div className={classes.actions}>
                    <WalletButton
                      onClick={handleCancelClick}
                      size="large"
                      className={classes.button}
                      variant="outlined"
                      disabled={busy}
                    >
                      {intl.formatMessage({ id: 'general.cancel' })}
                    </WalletButton>
                  </div>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default AuthorizeForm
