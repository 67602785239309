import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import * as PropTypes from 'prop-types'
import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  dialog: {
    backgroundColor: theme.palette.background.contrast,
  },
  dialogFooter: {
    padding: 5,
    // backgroundColor: theme.palette.background.confirmationBg,
  },
  closeBtn: {
    position: 'absolute',
    top: 10,
    right: 15,
    color: theme.palette.secondary.dark,
    fontSize: '2rem',
    cursor: 'pointer',
  },
}))

const MessageDialog = ({
  open,
  handleClose,
  title,
  message,
  buttonTitle = 'OK',
}) => {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="message-dialog-title"
    >
      <div className={classes.dialog}>
        <div className={classes.closeBtn} onClick={handleClose}>
          x
        </div>
        <DialogTitle id="message-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
      </div>
      <div className={classes.dialogFooter}>
        <DialogActions>
          <Button
            color="primary"
            size="large"
            variant="contained"
            onClick={handleClose}
          >
            {buttonTitle}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}

MessageDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  buttonTitle: PropTypes.string,
}

export default MessageDialog
