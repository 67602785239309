if (process.env.REACT_APP_VAULT_ENV === 'local') {
  module.exports = require('./config-local')
} else if (process.env.REACT_APP_VAULT_ENV === 'stage') {
  module.exports = require('./config-stage')
} else if (process.env.REACT_APP_VAULT_ENV === 'preprod') {
  module.exports = require('./config-preprod')
} else if (process.env.REACT_APP_VAULT_ENV === 'prod') {
  module.exports = require('./config-prod')
} else if (process.env.REACT_APP_VAULT_ENV === 'next') {
  module.exports = require('./config-next')
} else if (process.env.REACT_APP_VAULT_ENV === 'e2e') {
  module.exports = require('./config-e2e')
} else if (process.env.REACT_APP_VAULT_ENV === 'dev') {
  module.exports = require('./config-dev')
} else if (process.env.REACT_APP_VAULT_ENV === 'wallet-v2') {
  module.exports = require('./config-wallet-v2')
} else if (process.env.REACT_APP_VAULT_ENV === 'testnet') {
  module.exports = require('./config-testnet')
} else {
  throw new Error('Unknown Vault environment')
}
