import * as PropTypes from 'prop-types'
import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import NonCustodialWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import DeleteIcon from '@material-ui/icons/Delete'
import Confirmation from '../../../common/confirmation/confirmation'

const Wallet = props => {
  const { wallet, setWallet, selectedWalletId, onDelete, onClick } = props
  const [deleting, setDeleting] = React.useState()

  const handleDelete = () => {
    onDelete(wallet)
  }

  const handleClick = () => {
    setWallet(wallet)
    onClick()
  }

  return (
    <>
      <ListItem
        button
        divider
        selected={wallet.id === selectedWalletId}
        onClick={handleClick}
      >
        <ListItemAvatar>
          <Avatar>
            <NonCustodialWalletIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={wallet.name}
          secondary={wallet.id}
          secondaryTypographyProps={{ noWrap: true }}
        />
        <ListItemSecondaryAction>
          <IconButton aria-label="Delete" onClick={() => setDeleting(true)}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      {deleting && (
        <Confirmation
          title="Delete wallet"
          message={`Are you sure that you want to delete "${wallet.name}" from this device?`}
          cancelText="Cancel"
          confirmText="Delete"
          onConfirm={handleDelete}
          handleClose={() => setDeleting()}
        />
      )}
    </>
  )
}

Wallet.propTypes = {
  wallet: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  selectedWalletId: PropTypes.string,
  setWallet: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Wallet
