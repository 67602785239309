import React from 'react'
import { observer } from 'mobx-react-lite'
import { parse, stringify } from 'query-string'
import { useIntl } from 'react-intl'
import { Link, Redirect } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/styles/makeStyles'
import MuiLink from '@material-ui/core/Link'
import Config from '../../config'
import accountsStore from '../../stores/accounts-store'
import CenterComponent from '../../common/center-component/center-component'
import AccountCard from '../linked-apps/components/app-card/account-card'
import DirectoryService from '../../lib/directory-service'
import ManageCustomDapp from './custom-dapp/custom-dapp-modal'
import useLocalStorage from '../../hooks/local-storage'
import { partition } from '../../lib/utils'

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing.unit,
    fontFamily: theme.typography.titleFont,
  },
  innerWrapper: {
    position: 'relative',
  },
  infoColor: {
    color: theme.palette.info.main,
  },
}))

const Dashboard = observer(({ location, location: { search } }) => {
  const classes = useStyles()
  const accounts = React.useContext(accountsStore)
  const [managing, setManaging] = React.useState(false)
  const [customDapps, setCustomDapps] = useLocalStorage('custom-dapps', [])
  const intl = useIntl()

  if (
    accounts.current.dappAccounts.isLoading ||
    accounts.current.dappAccounts.apps.some(({ isLoading }) => isLoading)
  ) {
    return (
      <CenterComponent>
        <CircularProgress />
      </CenterComponent>
    )
  }

  const [chromiaAccounts = [], dappAccounts = []] = partition(
    accounts.current.dappAccounts.accounts,
    i => true === i.dapp.isDefaultChain,
  )

  // Corite: Hide default chain unless it is really functional
  chromiaAccounts.length = 0

  const query = search && parse(search)
  const dapps = new DirectoryService()
    .getDapps()
    // Corite: hide those dapps that user has already authorized to.
    .filter(
      dapp =>
        !dappAccounts.find(
          acc => acc.dapp.id.toUpperCase() === dapp.id.toUpperCase(),
        ),
    )
    .map(dapp => ({ dapp }))

  const handleAddDapp = dapp => {
    setCustomDapps([...customDapps, dapp])
  }

  const handleEditDapp = (updated, dapp) => {
    setCustomDapps(
      customDapps.map(d => {
        if (d.rid !== dapp.rid) return d
        return Object.assign({}, d, updated)
      }),
    )
  }

  const handleRemoveDapp = dapp => {
    setCustomDapps(customDapps.filter(({ rid }) => rid !== dapp.rid))
  }

  if (query && query.route) {
    const { route, ...withoutRoute } = query
    return (
      <Redirect
        to={{
          ...location,
          pathname: route,
          search: stringify(withoutRoute),
        }}
      />
    )
  }

  if (dappAccounts.length === 1) {
    const [account] = dappAccounts

    return <Redirect to={`/dashboard/${account.dapp.id}/${account.id}`} />
  }

  return (
    <div style={{ marginBottom: 100 }}>
      <Grid container className={classes.innerWrapper}>
        <Typography variant="h3" paragraph className={classes.title}>
          {intl.formatMessage({ id: 'dashboard.welcome' })}
        </Typography>
      </Grid>
      <div>
        {chromiaAccounts.length > 0 && (
          <>
            <Typography variant="h6">
              {intl.formatMessage({ id: 'dashboard.account' })}
            </Typography>
            <Divider />
            <br />
            <br />
            <Grid container spacing={16}>
              {chromiaAccounts.map(account => (
                <Grid item key={account.id} xs={12} sm={6} md={4} lg={3}>
                  <Link
                    to={`/dashboard/${account.dapp.id}/${account.id}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <AccountCard account={account} />
                  </Link>
                </Grid>
              ))}
            </Grid>
            <br />
            <br />
          </>
        )}

        {dappAccounts.length > 0 && (
          <>
            <Typography variant="h6">
              {intl.formatMessage({ id: 'dashboard.dappAccounts' })}
            </Typography>
            <Divider />
            <br />
            <br />

            <Grid container spacing={16}>
              {dappAccounts.map(account => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  key={`${account.dapp.id};${account.id}`}
                >
                  <Link
                    to={`/dashboard/${account.dapp.id}/${account.id}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <AccountCard account={account} />
                  </Link>
                </Grid>
              ))}
            </Grid>
            <br />
            <br />
          </>
        )}

        {dapps.length > 0 && (
          <>
            <Grid container justify="space-between">
              <Grid item xs>
                <Typography variant="h6">
                  {intl.formatMessage({ id: 'dashboard.allAccounts' })}
                </Typography>
              </Grid>
              {Config.canAddDapp && (
                <Grid item xs="auto">
                  <div onClick={() => {}}>
                    <Grid container alignItems="center">
                      <MuiLink
                        underline="none"
                        variant="body1"
                        className={classes.infoColor}
                        onClick={() => setManaging(null)}
                      >
                        {intl.formatMessage({ id: 'dashboard.addDapp' })}
                      </MuiLink>
                    </Grid>
                  </div>
                </Grid>
              )}
            </Grid>
            <Divider />
            <br />
            <br />
            <Grid container spacing={16}>
              {dapps.map(account => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={account.dapp.id}>
                  <a
                    href={account.dapp.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <AccountCard account={account} />
                  </a>
                </Grid>
              ))}
              {customDapps.map(dapp => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={dapp.rid}>
                  <a
                    href={dapp.web}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <AccountCard
                      account={{
                        dapp: {
                          custom: true,
                          name: dapp.name,
                          id: dapp.rid,
                          url: dapp.web,
                        },
                      }}
                      onEditClick={() => setManaging(dapp)}
                    />
                  </a>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </div>
      {managing !== false && (
        <ManageCustomDapp
          dapp={managing}
          onAdd={handleAddDapp}
          onEdit={handleEditDapp}
          onRemove={handleRemoveDapp}
          handleClose={() => setManaging(false)}
        />
      )}
    </div>
  )
})
export default Dashboard
