const primary = {
  light: '#ffb5c6',
  main: '#ff003c',
  dark: '#d20032',
}

const secondary = {
  light: '#f6f8fb',
  main: '#405A73',
  dark: '#0C2239',
  lightText: '#738AA4',
  darkText: '#405a73',
}

const error = {
  light: '#ffb5c6',
  main: '#ff003c',
  dark: '#d20032',
}

const getPalette = type => {
  return {
    primary,
    secondary,
    error,
    info: { main: type === 'dark' ? '#FFB600' : '#ff003c' },
  }
}

export default getPalette
