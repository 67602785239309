import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/styles'
import React, { Fragment } from 'react'
import { useIntl } from 'react-intl'
import Toast from '../../../../common/toast/toast'
import { copyToClipboard } from '../../../../common/clipboard/clipboard'

const useStyles = makeStyles(theme => ({
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  appImageWrapper: {
    position: 'relative',
    display: 'flex',
    cursor: 'pointer',
    '& svg': {
      borderRadius: 8,
      '& rect': { fill: '#000' },
      '& path': { fill: '#ff003c' },
      '& path + path': { fill: '#fff' },
    },
  },
  popper: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-all',
    color: 'white',
  },
}))

const AppImage = ({
  appId,
  appIcon,
  userId,
  appSize,
  withTooltip,
  className,
}) => {
  const classes = useStyles()
  const Wrapper = withTooltip ? Tooltip : Fragment
  const [snackbar, setSnackbar] = React.useState(false)
  const intl = useIntl()

  const copy = e => {
    if (!withTooltip) return
    e.preventDefault()
    copyToClipboard(`${appId};${userId}`)
    setSnackbar(true)
  }

  let image
  if (appIcon) {
    image = <img src={appIcon} width={appSize} height={appSize} alt="" />
  } else {
    image = (
      <svg width={appSize} height={appSize} data-jdenticon-value={appId} />
    )
  }

  return (
    <Grid container justify="center" className={className}>
      <Grid item className={classes.appImageWrapper} onClick={copy}>
        <Wrapper
          {...(withTooltip && {
            title: `Address:
        ${appId};${userId}

        Click to copy to clipboard`,
            placement: 'top',
            classes: { popper: classes.popper },
          })}
        >
          <div className={classes.imageContainer}>{image}</div>
        </Wrapper>
      </Grid>
      {snackbar && (
        <Toast
          message={intl.formatMessage(
            { id: 'general.clipboard' },
            {
              field: intl.formatMessage({
                id: 'general.address',
              }),
            },
          )}
          close={() => setSnackbar()}
        />
      )}
    </Grid>
  )
}

export default AppImage
