import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Tab from '@material-ui/core/Tab'
import MuiLink from '@material-ui/core/Link'
import { useIntl } from 'react-intl'
import Config from '../../../config'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { observer } from 'mobx-react-lite'
import { parse } from 'query-string'
import React from 'react'
import accountsStore from '../../../stores/accounts-store'
import Balances from '../../transfers/components/balances/balances'
import TransactionHistory from '../../transfers/components/history/history'
import Receive from '../../transfers/components/receive'
import Transfer from '../../transfers/components/transfer'
import { ReactComponent as ScanQrCode } from '../../../resources/icons/qr-code-scan.svg'
import { ReactComponent as ShowQrCode } from '../../../resources/icons/QrCode.svg'
import QRCodeDialog from '../../../common/qr-code-dialog/qr-code-dialog'
import { parseQRStringType, QRType } from '../../../lib/qr-util'
import QRScannerDialog from '../../../common/qr-scanner-dialog/qr-scanner-dialog'
import MessageDialog from '../../../common/message-dialog/message-dialog'
import RequestModal from '../request/request-modal'
import AppImage from '../../linked-apps/components/app-card/app-image'
import DirectoryService from '../../../lib/directory-service'

const useStyles = makeStyles(theme => ({
  header: {
    margin: '40px 0 60px',
  },
  infoColor: {
    color: theme.palette.info.main,
  },
  imageContainer: {
    marginRight: '20px',
  },
  titleContainer: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  assets: {
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
  deauthContainer: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  title: {
    fontSize: '20px',
    marginBottom: 0,
    fontFamily: theme.typography.titleFont,
  },
  launchDapp: {
    color: theme.palette.text.primary,
    border: '1px solid #464145',
    borderRadius: 46,
    display: 'inline-flex',
    padding: '10px 25px',
    textDecoration: 'inherit',
    cursor: 'pointer',
    '& svg': {
      marginLeft: 10,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '4px 10px',
      '& p': {
        fontSize: 14,
      },
      '& svg': {
        width: 15,
        height: 15,
        marginTop: 2,
      },
    },
  },
  deauthorize: {
    cursor: 'pointer',
  },
  sendTokens: {
    background: theme.palette.background.contrast,
    padding: 20,
    height: '100%',
    position: 'relative',
    borderRadius: 5,
    ...(theme.type !== 'dark' && {
      boxShadow: `0 0 3px 3px rgba(0, 0, 0, 0.12)`,
    }),
  },
  qrcode: {
    cursor: 'pointer',
    position: 'absolute',
    color: theme.palette.text.secondary,
    top: 32,
    right: 25,
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing.unit,
      position: 'static',
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  or: {
    color: theme.palette.text.secondary,
    margin: `${theme.spacing.unit}px 0`,
  },
  address: {
    background: theme.palette.background.secondary,
    wordBreak: 'break-all',
    borderRadius: theme.spacing.unit / 2,
    '& p': {
      padding: theme.spacing.unit * 2,
    },
  },
  clipboard: {
    cursor: 'pointer',
  },
  qrCodeIcon: {
    marginRight: 8,
  },
  link: {
    marginTop: '20px',
    color: '#ff003c',
    display: 'inline-block',

    '&:hover': {
      textDecoration: 'none',
    },
  },
  tabsRef: {
    height: '50px',
    paddingRight: '125px',
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
    },
  },
}))

const Details = observer(props => {
  const {
    history,
    location: { search, pathname },
    match,
  } = props

  const classes = useStyles()
  const accounts = React.useContext(accountsStore)
  const [receiveQRString, setReceiveQRString] = React.useState('')
  const [dialogMessage, setDialogMessage] = React.useState('')
  const [showQRScanner, setShowQRScanner] = React.useState(false)
  const [showQRCode, setShowQRCode] = React.useState(false)
  const [transferData, setTransferData] = React.useState({})
  const [account, setAccount] = React.useState()
  const [tab, setTab] = React.useState('send')
  const [requestModalTab, setRequestModalTab] = React.useState('')
  const [allAssets, setAllAssets] = React.useState(null)
  const intl = useIntl()

  let dappConfig
  let dappIcon
  if (account) {
    dappConfig = new DirectoryService().getChainConfig(account.dapp.id)
    dappIcon = dappConfig?.appIcon || account.dapp.appIcon
  }

  React.useEffect(() => {
    accounts.current.dappAccounts.session.blockchain
      .getAllAssets()
      .then(data =>
        data.map(_asset => ({
          name: _asset.name,
          id: _asset.id.toString('hex'),
        })),
      )
      .then(setAllAssets)
  }, []) // eslint-disable-line

  // const onDeauthorize = async () => {
  //   if (!account) {
  //     return
  //   }
  //   await account.deauthorize()
  // }

  const handleTransferDataChange = transferData => {
    setTransferData(transferData)
  }

  const updateTransferData = transferData => {
    const { accountId, dappId, amount, assetId } = transferData

    setTransferData({
      applicationId: dappId && dappId.toUpperCase(),
      accountId: accountId && accountId.toUpperCase(),
      amount: amount && parseInt(amount),
      assetId: assetId && assetId.toUpperCase(),
    })
  }

  const handleQRPaymentScan = qrString => {
    try {
      updateTransferData(parseQRStringType(QRType.Payment, qrString))
    } catch {
      setDialogMessage(intl.formatMessage({ id: 'addDapp.errorQr' }))
    } finally {
      setShowQRScanner(false)
    }
  }

  React.useEffect(() => {
    if (search.length > 0) {
      setTab('send')
      updateTransferData(parse(search))
      history.replace({ pathname })
    }
  }, [search]) // eslint-disable-line

  React.useEffect(() => {
    accounts.current.dappAccounts
      .getById(match.params.dappId, match.params.accountId)
      .then(_account => {
        _account && setAccount(_account)
      })
  }, [accounts.current.dappAccounts.isLoading]) // eslint-disable-line

  // const percentage = account
  //   ? Math.round(
  //       (account.points /
  //         account.account.blockchain.info.rateLimitInfo.maxPoints) *
  //         100,
  //     )
  //   : 0

  return (
    <div>
      <Grid container alignItems="center" className={classes.header}>
        <Grid item xs="auto" className={classes.imageContainer}>
          <AppImage
            appId={account && account.dapp.id}
            appIcon={dappIcon}
            userId={account && account.id}
            appSize={120}
            withTooltip
          />
        </Grid>
        <Grid item xs>
          <Grid container className={classes.titleContainer}>
            <Grid item xs>
              <Grid item>
                <Typography variant="h3" paragraph className={classes.title}>
                  {account && account.dapp.name}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className="opacity">
                  Corite
                </Typography>
              </Grid>
            </Grid>
            {/*<Grid item xs="auto">*/}
            {/*  <Grid container spacing={24} alignItems="center">*/}
            {/*    {account && (*/}
            {/*      <Grid item xs="auto">*/}
            {/*        <Grid container direction="column" spacing={8}>*/}
            {/*          <Grid container alignItems="center">*/}
            {/*            <Grid item xs>*/}
            {/*              <Typography color="textSecondary">*/}
            {/*                {intl.formatMessage(*/}
            {/*                  { id: 'dapp.pointsLeft' },*/}
            {/*                  { percent: percentage },*/}
            {/*                )}*/}
            {/*              </Typography>*/}
            {/*            </Grid>*/}
            {/*            <Tooltip*/}
            {/*              title={*/}
            {/*                <div>*/}
            {/*                  <Typography style={{ color: 'white' }}>*/}
            {/*                    {intl.formatMessage(*/}
            {/*                      { id: 'dapp.tooltip.title' },*/}
            {/*                      {*/}
            {/*                        used: account.points,*/}
            {/*                        max:*/}
            {/*                          account.account.blockchain.info*/}
            {/*                            .rateLimitInfo.maxPoints,*/}
            {/*                      },*/}
            {/*                    )}*/}
            {/*                  </Typography>*/}
            {/*                  <Typography*/}
            {/*                    style={{ color: 'rgba(255, 255, 255, 0.7)' }}*/}
            {/*                    variant="caption"*/}
            {/*                  >*/}
            {/*                    {intl.formatMessage({*/}
            {/*                      id: 'dapp.tooltip.note',*/}
            {/*                    })}*/}
            {/*                  </Typography>*/}
            {/*                  <Typography*/}
            {/*                    style={{ color: 'rgba(255, 255, 255, 0.7)' }}*/}
            {/*                    variant="caption"*/}
            {/*                  >*/}
            {/*                    {intl.formatMessage(*/}
            {/*                      { id: 'dapp.tooltip.note1' },*/}
            {/*                      {*/}
            {/*                        span:*/}
            {/*                          account.account.blockchain.info*/}
            {/*                            .rateLimitInfo.recoveryTime / 1000,*/}
            {/*                      },*/}
            {/*                    )}*/}
            {/*                  </Typography>*/}
            {/*                </div>*/}
            {/*              }*/}
            {/*            >*/}
            {/*              <Typography*/}
            {/*                color="textSecondary"*/}
            {/*                style={{ marginLeft: 8, height: 20 }}*/}
            {/*              >*/}
            {/*                <HelpOutline fontSize="small" color="inherit" />*/}
            {/*              </Typography>*/}
            {/*            </Tooltip>*/}
            {/*          </Grid>*/}
            {/*          <Grid item>*/}
            {/*            <LinearProgress*/}
            {/*              variant="determinate"*/}
            {/*              value={percentage}*/}
            {/*            />*/}
            {/*          </Grid>*/}
            {/*          {Config.canRequest && Config.chainId === account.dapp.id && (*/}
            {/*            <Grid item container justify="center">*/}
            {/*              <MuiLink*/}
            {/*                underline="none"*/}
            {/*                variant="body1"*/}
            {/*                className={classes.infoColor}*/}
            {/*                onClick={() => setRequestModalTab('points')}*/}
            {/*              >*/}
            {/*                {intl.formatMessage({ id: 'dapp.getMorePoints' })}*/}
            {/*              </MuiLink>*/}
            {/*            </Grid>*/}
            {/*          )}*/}
            {/*        </Grid>*/}
            {/*      </Grid>*/}
            {/*    )}*/}
            {/*    {account && !account.dapp.isDefaultChain && (*/}
            {/*      <Grid item xs="auto">*/}
            {/*        <Grid*/}
            {/*          container*/}
            {/*          direction="column"*/}
            {/*          className={classes.deauthContainer}*/}
            {/*          spacing={8}*/}
            {/*        >*/}
            {/*          <Grid item>*/}
            {/*            <a*/}
            {/*              className={classes.launchDapp}*/}
            {/*              href={*/}
            {/*                account &&*/}
            {/*                `${account.dapp.url}/vault-login?accountId=${account.id}`*/}
            {/*              }*/}
            {/*              target="_blank"*/}
            {/*              rel="noopener noreferrer"*/}
            {/*            >*/}
            {/*              <Typography variant="body1">*/}
            {/*                {intl*/}
            {/*                  .formatMessage({ id: 'dapp.launch' })*/}
            {/*                  .toUpperCase()}*/}
            {/*              </Typography>*/}
            {/*              <ExternalLink />*/}
            {/*            </a>*/}
            {/*          </Grid>*/}
            {/*          <Grid item className={classes.deauthorize}>*/}
            {/*            <Typography*/}
            {/*              variant="body2"*/}
            {/*              className="opacity"*/}
            {/*              align="right"*/}
            {/*              onClick={onDeauthorize}*/}
            {/*            >*/}
            {/*              {intl.formatMessage({ id: 'dapp.deauthorize' })}*/}
            {/*            </Typography>*/}
            {/*          </Grid>*/}
            {/*        </Grid>*/}
            {/*      </Grid>*/}
            {/*    )}*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={32}>
        <Grid item xs={12} md={4} className={classes.assets}>
          <Grid container direction="column" className={classes.sendTokens}>
            <Grid container justify="space-between">
              <Grid item>
                <Typography variant="h6">
                  {intl.formatMessage({ id: 'general.assets' })}
                </Typography>
              </Grid>
              {Config.canRequest &&
                account &&
                Config.chainId === account.dapp.id && (
                  <Grid item>
                    <MuiLink
                      underline="none"
                      variant="body1"
                      className={classes.infoColor}
                      onClick={() => setRequestModalTab('assets')}
                    >
                      {intl.formatMessage({ id: 'dapp.getMoreAssets' })}
                    </MuiLink>
                  </Grid>
                )}
            </Grid>
            <br />
            <Balances account={account} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container direction="column" className={classes.sendTokens}>
            <Tabs className={classes.tabsRef} value={tab} textColor="secondary">
              <Tab
                label={intl.formatMessage({ id: 'dapp.tab.sendTokens' })}
                value="send"
                onClick={() => setTab('send')}
              />
              <Tab
                label={intl.formatMessage({ id: 'dapp.tab.receiveTokens' })}
                value="receive"
                onClick={() => setTab('receive')}
              />
            </Tabs>
            <br />
            {tab === 'send' && (
              <div
                className={classes.qrcode}
                onClick={() => setShowQRScanner(true)}
              >
                <Grid container alignItems="center">
                  <ScanQrCode width={18} height={18} />
                  <Typography variant="caption" inline className="opacity7">
                    &nbsp;&nbsp;{intl.formatMessage({ id: 'dapp.scanQrCode' })}
                  </Typography>
                </Grid>
                <Hidden smUp>
                  <Typography variant="caption" className={classes.or}>
                    - {intl.formatMessage({ id: 'general.or' }).toUpperCase()} -
                  </Typography>
                </Hidden>
              </div>
            )}
            {tab === 'send' && (
              <Grid container direction="column">
                {account && (
                  <Transfer
                    account={account}
                    onSuccess={() => setTransferData({})}
                    onChange={handleTransferDataChange}
                    transferData={transferData}
                  />
                )}
              </Grid>
            )}
            {tab === 'receive' && (
              <div
                className={classes.qrcode}
                onClick={() => setShowQRCode(true)}
              >
                <Grid container alignItems="center">
                  <ShowQrCode width={18} height={18} />
                  <Typography variant="caption" inline className="opacity7">
                    &nbsp;&nbsp;{intl.formatMessage({ id: 'dapp.showQrCode' })}
                  </Typography>
                </Grid>
                <Hidden smUp>
                  <Typography variant="caption" className={classes.or}>
                    &nbsp;
                  </Typography>
                </Hidden>
              </div>
            )}
            {tab === 'receive' && (
              <Grid container direction="column">
                <Receive
                  account={account}
                  onChange={qrString => setReceiveQRString(qrString)}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={32}>
        <Grid item container direction="column" spacing={8}>
          <br />
          <br />
          <Grid item>
            <Typography variant="subtitle1" className={classes.section}>
              {intl.formatMessage({ id: 'dapp.txHistory' })}
            </Typography>
          </Grid>
          <Grid item>
            <TransactionHistory account={account} />
          </Grid>
        </Grid>
      </Grid>
      <QRScannerDialog
        open={showQRScanner}
        title={intl.formatMessage({ id: 'dapp.recQrCode' })}
        onClose={() => setShowQRScanner(false)}
        onSuccess={handleQRPaymentScan}
      />
      <QRCodeDialog
        open={showQRCode}
        onClose={() => setShowQRCode(false)}
        qrString={receiveQRString}
      />
      {dialogMessage && (
        <MessageDialog
          open
          title={intl.formatMessage({ id: 'general.error' })}
          message={dialogMessage}
          handleClose={() => setDialogMessage('')}
        />
      )}
      {account && requestModalTab && allAssets && (
        <RequestModal
          open
          assets={allAssets}
          onSuccess={() => {
            console.log('account = ', account)
            account.loadData()
          }}
          maxPointsToRequest={
            account.account.blockchain.info.rateLimitInfo.maxPoints -
            account.points
          }
          accountId={account.id}
          initialTab={requestModalTab}
          onClose={() => setRequestModalTab('')}
        />
      )}
    </div>
  )
})

export default Details
