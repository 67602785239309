import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import { ReactComponent as BackArrow } from '../resources/icons/BackArrow.svg'

const useStyles = makeStyles(() => ({
  button: {
    color: '#738aa4',
    position: 'relative',
    flex: '1 0 50%',
    padding: 0,
    borderRadius: 4,
    lineHeight: '38px',
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: '"Montserrat", "Verdana", "Tahoma", sans-serif',
    textDecoration: 'underline',
    textTransform: 'capitalize',

    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'none',
    },
  },
  text: {
    display: 'flex',
  },
  back: {
    color: '#738aa4',
    height: '24px',
    width: '24px',
    marginRight: '6px',
  },
}))

const BackButton = ({
  color = '#738aa4',
  variant = 'text',
  size = 'large',
  className,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <Button
      size={size}
      color={color}
      variant={variant}
      className={`${classes.button} ${className ? className : ''}`}
      {...rest}
    >
      <BackArrow className={classes.back} />
      <div className={classes.text}>Back</div>
    </Button>
  )
}

export default BackButton
