import React, { useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import accountsStore from '../../stores/accounts-store'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Toast from '../../common/toast/toast'
import { ReactComponent as LinkChain } from '../../resources/icons/LinkChain.svg'
import { copyToClipboard } from '../../common/clipboard/clipboard'

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    margin: 'auto',
    maxWidth: 600,
  },
  title: {
    textAlign: 'left',
    marginTop: theme.spacing.unit,
    fontFamily: theme.typography.titleFont,
  },
  innerWrapper: {
    margin: '30px auto',
    padding: 30,
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: 6,
    boxShadow: '0 1px 0 #bdcbd9',
  },
}))

const AccountDetails = () => {
  const classes = useStyles()
  const accounts = useContext(accountsStore)
  const [toastMessage, setToastMessage] = React.useState()
  const [fieldValueVisibility, setFieldValueVisibility] = useState({})
  const intl = useIntl()
  const handleFieldValueVisibility = field => () => {
    setFieldValueVisibility(fieldValueVisibility => ({
      ...fieldValueVisibility,
      [field]: !fieldValueVisibility[field],
    }))
  }

  const copy = (value, message) => () => {
    copyToClipboard(value)
    setToastMessage(message)
  }

  return (
    <div className={classes.wrapper}>
      <Typography variant="h3" paragraph className={classes.title}>
        {intl.formatMessage({ id: 'account.title' })}
      </Typography>
      <div className={classes.innerWrapper}>
        <Grid container direction="column" spacing={24}>
          <Grid item>
            <TextField
              variant="filled"
              label={intl.formatMessage({ id: 'account.id' })}
              value={accounts.current.id}
              fullWidth
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label={intl.formatMessage({ id: 'account.id.copy' })}
                      onClick={copy(
                        accounts.current.id,
                        intl.formatMessage(
                          { id: 'general.clipboard' },
                          {
                            field: intl.formatMessage({
                              id: 'account.id',
                            }),
                          },
                        ),
                      )}
                    >
                      <LinkChain />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              variant="filled"
              label={intl.formatMessage({ id: 'account.publicKey' })}
              value={accounts.current.publicKey.toHex()}
              fullWidth
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="Click to copy the public key"
                      onClick={copy(
                        accounts.current.publicKey.toHex(),
                        intl.formatMessage(
                          { id: 'general.clipboard' },
                          {
                            field: intl.formatMessage({
                              id: 'account.publicKey',
                            }),
                          },
                        ),
                      )}
                    >
                      <LinkChain />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {accounts.current.mnemonic && (
            <Grid item>
              <TextField
                fullWidth
                label={intl.formatMessage({ id: 'account.backupPhrase' })}
                variant="filled"
                value={accounts.current.mnemonic}
                disabled
                type={fieldValueVisibility.phrase ? 'text' : 'password'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="Click to copy the backup phrase"
                        onClick={copy(
                          accounts.current.mnemonic,
                          intl.formatMessage(
                            { id: 'general.clipboard' },
                            {
                              field: intl.formatMessage({
                                id: 'account.backupPhrase',
                              }),
                            },
                          ),
                        )}
                      >
                        <LinkChain />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle backup phrase visibility"
                        onClick={handleFieldValueVisibility('phrase')}
                      >
                        {fieldValueVisibility.phrase ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          <Grid item>
            <TextField
              label={intl.formatMessage({ id: 'account.privateKey' })}
              variant="filled"
              value={accounts.current.privateKey.toHex()}
              fullWidth
              disabled
              type={fieldValueVisibility.privateKey ? 'text' : 'password'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="Click to copy the private key"
                      onClick={copy(
                        accounts.current.privateKey.toHex(),
                        intl.formatMessage(
                          { id: 'general.clipboard' },
                          {
                            field: intl.formatMessage({
                              id: 'account.privateKey',
                            }),
                          },
                        ),
                      )}
                    >
                      <LinkChain />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle private key visibility"
                      onClick={handleFieldValueVisibility('privateKey')}
                    >
                      {fieldValueVisibility.privateKey ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </div>
      {toastMessage && (
        <Toast message={toastMessage} close={() => setToastMessage(null)} />
      )}
    </div>
  )
}

export default AccountDetails
