import React from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { makeStyles } from '@material-ui/styles'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { ReactComponent as ProfileImage } from '../../../../resources/icons/vault.svg'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& > div': {
      margin: 0,
    },
  },
  profileIcon: {
    height: 34,
    width: 34,
    display: 'flex',
    '& svg': {
      margin: 'auto',
    },
  },
  paper: {
    marginRight: theme.spacing.unit * 2,
  },
  headerContent: {
    color: '#0C2239',
  },
  profileMenu: {
    padding: 0,
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: '"Montserrat", "Verdana", "Tahoma", sans-serif',

    '& > *': {
      minWidth: '122px',
      padding: '10px 16px',
      color: '#738aa4',

      '&:hover': {
        borderRadius: 0,
        color: '#0C2239',
        backgroundColor: '#f6f8fb',
      },
    },
  },
}))

const Profile = ({ items }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const anchorEl = React.useRef(null)
  const intl = useIntl()
  const handleToggle = () => setOpen(!open)

  const handleClose = event => {
    if (anchorEl.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <div
        className={classes.root}
        ref={anchorEl}
        aria-owns={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <div className={classes.profileIcon}>
          <ProfileImage height={34} width={34} />
        </div>
        {/*<div className={classes.headerContent}>{name}</div>*/}
        {/*<ExpandMore className={classes.headerContent} />*/}
      </div>
      <Popper open={open} anchorEl={anchorEl.current} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{
              marginRight: '47px',
              marginLeft: '-47px',
              marginTop: '9px',
              backgroundColor: '#fff',
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper square="false">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  onClick={() => setOpen(false)}
                  className={classes.profileMenu}
                >
                  {items.map(item =>
                    typeof item.action === 'function' ? (
                      <MenuItem onClick={item.action}>
                        {intl.formatMessage({ id: item.title })}
                      </MenuItem>
                    ) : (
                      <MenuItem component={Link} to={item.action}>
                        {intl.formatMessage({ id: item.title })}
                      </MenuItem>
                    ),
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default Profile
