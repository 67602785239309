import React, { useState, useContext } from 'react'
import ImportMnemonic from './components/import-mnemonic'
import AccountForm from '../../common/account-form/account-form'
import accountsStore from '../../stores/accounts-store'
import { makeStyles } from '@material-ui/styles'

const Step = Object.freeze({
  Import: 'Import',
  Form: 'Form',
})

const useStyles = makeStyles(theme => ({
  innerWrapper: {
    position: 'relative',
    maxWidth: 600,
    margin: 'auto',
  },
  leftShapes: {
    color: theme.palette.text.primary,
    position: 'absolute',
    left: -220,
    top: 10,
  },
  rightShapes: {
    color: theme.palette.text.primary,
    position: 'absolute',
    right: -220,
    top: 10,
  },
}))

const Import = () => {
  const classes = useStyles()
  const accounts = useContext(accountsStore)
  const [step, setStep] = useState(Step.Import)
  const [mnemonic, setMnemonic] = useState()

  const handleImportMnemonicContinue = mnemonic => {
    setMnemonic(mnemonic)
    setStep(Step.Form)
  }

  const handleActionFormContinue = async account => {
    await accounts.addAccountAndLogin(account.name, mnemonic, account.password)
  }

  return (
    <div className={classes.innerWrapper}>
      {/*<Hidden smDown>*/}
      {/*  <LeftShapes className={classes.leftShapes} />*/}
      {/*  <RightShapes className={classes.rightShapes} />*/}
      {/*</Hidden>*/}
      {step === Step.Import && (
        <ImportMnemonic onContinue={handleImportMnemonicContinue} />
      )}
      {step === Step.Form && (
        <AccountForm onContinue={handleActionFormContinue} />
      )}
    </div>
  )
}

export default Import
