import React from 'react'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import makeStyles from '@material-ui/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { decryptSeed } from '../../../lib/crypto'
import WalletButton from '../../../material/button'
import AppImage from '../../linked-apps/components/app-card/app-image'
import RoboHash from '../../../common/robo-hash/robo-hash'
import accountsStore from '../../../stores/accounts-store'
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
  dialog: {
    backgroundColor: theme.palette.background.contrast,
  },
  dialogFooter: {
    padding: '15px 30px 30px',
    // backgroundColor: theme.palette.background.confirmationBg,
  },
  roboHash: {
    marginTop: theme.spacing.unit * 2,
  },
  address: {
    background: theme.palette.background.secondary,
    wordBreak: 'break-all',
    marginTop: theme.spacing.unit * 2,
    borderRadius: theme.spacing.unit / 2,
    '& p': {
      padding: theme.spacing.unit * 2,
    },
  },
  assetContainer: {
    height: 30,
    paddingTop: theme.spacing.unit / 2,
  },
  closeBtn: {
    position: 'absolute',
    top: 10,
    right: 15,
    color: theme.palette.secondary.dark,
    fontSize: '2rem',
    cursor: 'pointer',
  },
}))

const TransferConfirmation = ({
  applicationName,
  applicationId,
  accountId,
  asset,
  amount,
  handleClose,
  handleConfirm,
}) => {
  const classes = useStyles()
  const isFullScreen = useMediaQuery('(max-width:600px)')
  const [password, setPassword] = React.useState('')
  const [validating, setValidating] = React.useState(false)
  const [validated, setValidated] = React.useState(false)
  const [error, setError] = React.useState('')
  const accounts = React.useContext(accountsStore)

  const checkPassword = async () => {
    const account = accounts.accounts.find(
      ({ id }) => id === accounts.current.id,
    )
    await decryptSeed(password, account.encryptedSeed)
  }

  const handleConfirmClick = async () => {
    if (validated) return handleConfirm()

    setValidating(true)
    try {
      await checkPassword()
      setValidated(true)
    } catch (err) {
      setError('Invalid password')
    } finally {
      setValidating(false)
    }
  }

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="confirmation-dialog-title"
      maxWidth="sm"
      fullWidth={true}
      fullScreen={isFullScreen}
    >
      <div className={classes.dialog}>
        <div className={classes.closeBtn} onClick={handleClose}>
          x
        </div>
        <DialogTitle id="confirmation-dialog-title">
          Confirm transfer
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={24} style={{ padding: 12 }}>
            <Grid item>
              <Typography variant="body1">
                Are you sure that you want to make this transfer?
              </Typography>
            </Grid>
            <Grid item container direction="row" spacing={32}>
              <Grid item sm={2} xs={3}>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="subtitle2" className="opacity7">
                      Amount
                    </Typography>
                  </Grid>
                  <Grid item>
                    <div className={classes.assetContainer}>
                      <Typography variant="body1">{amount}</Typography>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={2} xs={3}>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="subtitle2" className="opacity7">
                      Asset
                    </Typography>
                  </Grid>
                  <Grid item>
                    <div className={classes.assetContainer}>
                      <Typography variant="body1">{asset}</Typography>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={8} xs={6}>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="subtitle2" className="opacity7">
                      Application
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      spacing={8}
                      alignItems="center"
                    >
                      <Grid item>
                        <AppImage appId={applicationId} appSize={30} inline />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">
                          {applicationName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={0} direction="column">
                <Grid item>
                  <Typography variant="subtitle2" className="opacity7">
                    Recipient address
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center" spacing={16}>
                    <Grid item xs="auto" className={classes.roboHash}>
                      <RoboHash id={accountId} size={70} inline />
                    </Grid>
                    <Grid item xs className={classes.address}>
                      <Typography variant="body1">{accountId}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </div>
      <div className={classes.dialogFooter}>
        <Grid container direction="row" alignItems="center" spacing={16}>
          <Grid item sm={7} xs={12}>
            {!validated && (
              <Grid item xs>
                <TextField
                  variant="filled"
                  label="Confirm Your Password"
                  fullWidth
                  type="password"
                  disabled={validating}
                  helperText={error}
                  error={!!error}
                  value={password}
                  onChange={({ target: { value } }) => {
                    setError('')
                    setPassword(value)
                  }}
                  autoFocus
                />
              </Grid>
            )}
          </Grid>
          <Grid item container sm={5} xs={12} justify="center">
            <Grid item>
              <WalletButton
                onClick={handleConfirmClick}
                disabled={!password || validating}
                busy={validating}
              >
                Confirm {validated ? 'Transfer' : 'Password'}
              </WalletButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}

export default TransferConfirmation
