import React, { useState, useEffect, useContext } from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { useIntl } from 'react-intl'
import {
  validate,
  required,
  equalValues,
  minLength,
} from '../../lib/validations'
import WalletButton from '../../material/button'
import accountsStore from '../../stores/accounts-store'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
  wrapper: {
    maxWidth: 600,
    margin: 'auto',
    marginTop: theme.spacing.unit,
  },
  title: {
    fontFamily: theme.typography.titleFont,
  },
  actions: {
    margin: `${theme.spacing.unit * 4}px 0`,
    textAlign: 'center',
    display: 'flex',
  },
  fields: {
    marginTop: theme.spacing.unit * 6,
  },
  or: {
    margin: `${theme.spacing.unit}px 0`,
  },
}))

const AccountForm = ({ onContinue }) => {
  const [account, setAccount] = useState({})
  const [error, setError] = useState('')
  const accounts = useContext(accountsStore)
  const classes = useStyles()
  const [busy, setBusy] = React.useState(false)
  const intl = useIntl()

  useEffect(() => {
    setError('')
  }, [account])

  const handleContinueClick = async e => {
    e.preventDefault()
    const { name, password, repeatPassword } = account

    if (accounts.hasAccount(name)) {
      setError('sign.up.error.used')
      return
    }

    if (
      !validate(
        [
          [name, [required('sign.up.error.name')]],
          [
            password,
            [
              required('sign.up.error.password'),
              minLength(6, 'sign.up.error.minPassword'),
            ],
          ],
          [repeatPassword, [equalValues(password, 'sign.up.error.verify')]],
        ],
        setError,
      )
    ) {
      return
    }

    setBusy(true)
    await onContinue({ name, password })
    setBusy(false)
  }

  const handleTextFieldChange = ({ target: { name, value } }) => {
    setAccount(account => ({ ...account, [name]: value }))
  }

  return (
    <form className={classes.wrapper} onSubmit={handleContinueClick}>
      <Typography variant="h3" paragraph className={classes.title}>
        {intl.formatMessage({ id: 'sign.up.title' })}
      </Typography>
      <Typography variant="subtitle1">
        {intl.formatMessage({ id: 'sign.up.no-custodial.subtitle' })}
      </Typography>
      {error && (
        <Typography color="error">
          {intl.formatMessage({ id: error })}
        </Typography>
      )}
      <Grid
        item
        container
        direction="column"
        spacing={24}
        className={classes.fields}
      >
        <Grid item xs>
          <TextField
            variant="filled"
            label={intl.formatMessage({ id: 'sign.up.name' })}
            name="name"
            fullWidth
            value={account.name || ''}
            onChange={handleTextFieldChange}
            autoFocus
          />
        </Grid>
        <Grid item xs>
          <TextField
            variant="filled"
            label={intl.formatMessage({ id: 'general.password' })}
            type="password"
            name="password"
            fullWidth
            value={account.password || ''}
            onChange={handleTextFieldChange}
          />
        </Grid>
        <Grid item xs>
          <TextField
            variant="filled"
            label={intl.formatMessage({ id: 'sign.up.verify' })}
            type="password"
            fullWidth
            name="repeatPassword"
            value={account.repeatPassword || ''}
            onChange={handleTextFieldChange}
          />
        </Grid>
      </Grid>
      <div className={classes.actions}>
        <WalletButton type="submit" busy={busy}>
          {intl.formatMessage({ id: 'sign.up.submit' })}
        </WalletButton>
      </div>
    </form>
  )
}

export default AccountForm
