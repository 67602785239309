import Hidden from '@material-ui/core/Hidden'
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import { useIntl } from 'react-intl'
import AppImage from '../../../linked-apps/components/app-card/app-image'
import HistoryPagination from './pagination'
import { useObserver } from 'mobx-react-lite'
import { ReactComponent as Sent } from '../../../../resources/icons/Sent.svg'
import { ReactComponent as Received } from '../../../../resources/icons/Received.svg'
import { ReactComponent as LinkChain } from '../../../../resources/icons/LinkChain.svg'
import Toast from '../../../../common/toast/toast'
import Config from '../.././../../config'
import { ReactComponent as ExternalLink } from '../../../../resources/icons/ExternalLink.svg'
import { copyToClipboard } from '../../../../common/clipboard/clipboard'

const useStyles = makeStyles(theme => ({
  clipboard: {
    cursor: 'pointer',
  },
  icons: {
    width: 'fit-content',
  },
  typeColumn: {
    display: 'flex',
    alignItems: 'center',
    height: 48,
    '& svg': {
      marginRight: 6,
      width: 20,
      height: 20,
    },
  },
  externalLink: {
    color: theme.palette.text.primary,
  },
}))

const TransactionHistory = ({ account }) => {
  const classes = useStyles()
  const [snackbar, setSnackbar] = React.useState()
  const isDevice = useMediaQuery('(max-width:600px)')
  const intl = useIntl()

  const copy = value => () => {
    copyToClipboard(value)
    setSnackbar(true)
  }

  let emptyRows = 0
  if (account && !account.paymentHistory.hasMore()) {
    emptyRows =
      account.paymentHistory.pageSize - account.paymentHistory.entries.length
  }

  const handleChangePage = (event, newPage) => {
    if (newPage === 0) {
      account.paymentHistory.loadFirstPage()
    } else if (newPage === account.paymentHistory.pageCount - 1) {
      account.paymentHistory.loadLastPage()
    } else if (newPage < account.paymentHistory.current) {
      account.paymentHistory.loadPrevPage()
    } else {
      account.paymentHistory.loadNextPage()
    }
  }

  const handleChangeRowsPerPage = event => {
    account.paymentHistoryPageSize = Number(event.target.value)
  }

  return useObserver(() => (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{intl.formatMessage({ id: 'general.type' })}</TableCell>
            <TableCell align="left">
              {intl.formatMessage({ id: 'general.toFrom' })}
            </TableCell>
            <TableCell align="left">
              {intl.formatMessage({ id: 'general.asset' })}
            </TableCell>
            <TableCell align="left">
              {intl.formatMessage({ id: 'general.amount' })}
            </TableCell>
            <Hidden smDown>
              <TableCell align="left">
                {intl.formatMessage({ id: 'general.timestamp' })}
              </TableCell>
            </Hidden>
            <TableCell align="left">
              {intl.formatMessage({ id: 'general.transaction' })}
            </TableCell>
          </TableRow>
        </TableHead>
        {account && (
          <>
            <TableBody>
              {account.paymentHistory.entries.map((row, index) => {
                const isSent = row.isInput
                return (
                  <TableRow key={index}>
                    <TableCell
                      align="left"
                      className={classes.typeColumn}
                      style={{
                        color: !isSent ? '#CB92F0' : '#FF405E',
                      }}
                    >
                      {!isSent ? <Received /> : <Sent />}
                      <Hidden smDown>
                        {intl
                          .formatMessage({
                            id: `general.${!isSent ? 'received' : 'sent'}`,
                          })
                          .toUpperCase()}
                      </Hidden>
                    </TableCell>
                    <TableCell align="left">
                      <AppImage
                        appId={row.other[0].chainId.toUpperCase()}
                        userId={row.other[0].accountId.toUpperCase()}
                        appSize={35}
                        userSize={30}
                        className={classes.icons}
                        withTooltip
                        inline
                      />
                    </TableCell>
                    <TableCell align="left">{row.asset}</TableCell>
                    <TableCell align="left">
                      {row.isInput ? -row.delta : row.delta}
                    </TableCell>
                    <Hidden smDown>
                      <TableCell align="left" component="th" scope="row">
                        {row.timestamp.toLocaleString()}
                      </TableCell>
                    </Hidden>
                    <TableCell align="center" component="th" scope="row">
                      {Config.explorerUrl ? (
                        <a
                          className={classes.externalLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${Config.explorerUrl}/${
                            account.dapp.id
                          }/tx/${row.transactionId.toHex()}`}
                        >
                          <ExternalLink width={18} height={18} />
                        </a>
                      ) : (
                        <LinkChain
                          width={18}
                          height={18}
                          onClick={copy(row.transactionId.toHex())}
                          className={classes.clipboard}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={6}
                  count={account.paymentHistory.totalCount}
                  rowsPerPage={account.paymentHistory.pageSize}
                  page={account.paymentHistory.current}
                  {...(isDevice && { labelRowsPerPage: '' })}
                  SelectProps={{
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={HistoryPagination}
                />
              </TableRow>
            </TableFooter>
          </>
        )}
      </Table>
      {snackbar && (
        <Toast
          message="Transaction id copied to clipboard"
          close={() => setSnackbar()}
        />
      )}
    </>
  ))
}

export default TransactionHistory
