import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/styles'
import { Observer, useObserver } from 'mobx-react-lite'
import { Redirect, Route, Switch } from 'react-router'
import routes, { publicRoutes } from '../routes'
import accountsStore from '../stores/accounts-store'
import appStateStore from '../stores/app-state-store'
import Header from './header/header'

const useStyles = makeStyles(theme => ({
  main: {
    maxHeight: 'calc(100vh - 48px)',
    overflow: 'auto',
  },
  body: {
    maxWidth: 1024,
    margin: 'auto',
    flex: 1,
    padding: theme.spacing.unit * 2,
    paddingBottom: '30px',
    width: '100%',
  },
}))

const Layout = () => {
  const classes = useStyles()
  const accounts = React.useContext(accountsStore)
  const appState = React.useContext(appStateStore)

  return useObserver(() => (
    <>
      <Header />
      <Grid container>
        <Grid item xs container direction="column" className={classes.main}>
          <div className={classes.body}>
            <Switch>
              {routes.map(({ Component, authGuard, routeProps }) => (
                <Route
                  {...routeProps}
                  key={routeProps.path}
                  render={props => (
                    <Observer
                      render={() => {
                        if (!accounts.current) {
                          const { pathname, search } = props.location
                          if (pathname === '/' && search.length > 0) {
                            appState.redirectAfterLogin = search
                            return (
                              <Redirect
                                to={{ ...props.location, search: '' }}
                              />
                            )
                          }

                          if (authGuard) return <Redirect to="/" />
                          return <Component {...props} />
                        } else {
                          if (appState.redirectAfterLogin) {
                            const search = appState.redirectAfterLogin
                            appState.redirectAfterLogin = null
                            return <Redirect to={{ pathname: '/', search }} />
                          }

                          const leaveApp = publicRoutes
                            .map(i => i.routeProps.path)
                            .includes(props.location.pathname)
                          if (leaveApp) {
                            return (
                              <Redirect
                                to={{
                                  ...props.location,
                                  pathname: '/dashboard',
                                }}
                              />
                            )
                          }
                          return <Component {...props} />
                        }
                      }}
                    />
                  )}
                />
              ))}
              <Redirect to="/" />
            </Switch>
          </div>
        </Grid>
      </Grid>
    </>
  ))
}

export default Layout
