import React from 'react'
import * as PropTypes from 'prop-types'
import withWidth from '@material-ui/core/es/withWidth'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import QRCode from 'qrcode.react'
import { useIntl } from 'react-intl'
import { ReactComponent as DownloadIcon } from '../../resources/icons/Download.svg'
import makeStyles from '@material-ui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeButton: {
    color: theme.palette.secondary.dark,
  },
  content: {
    padding: '0 37px 20px',
    width: 'min-content',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  qr: {
    width: 256,
    height: 256,
  },
  qrContainer: {
    padding: 25,
    backgroundColor: '#fff',
  },
  saveQRIcon: {
    marginRight: 10,
  },
}))

const QRCodeDialog = ({ open, onClose, qrString, width }) => {
  const classes = useStyles()
  const intl = useIntl()

  const downloadQR = () => {
    const canvas = document.getElementById('qr-code')
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream')
    let downloadLink = document.createElement('a')
    downloadLink.href = pngUrl
    downloadLink.download = 'vault-qr-code.png'
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  return (
    <Dialog open={open} onClose={onClose} fullScreen={width === 'xs'}>
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h6">
          {intl.formatMessage({ id: 'qr.modal.title' })}
        </Typography>
        {onClose ? (
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container direction="column" spacing={16}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {intl.formatMessage({ id: 'qr.modal.note' })}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ margin: 'auto' }}>
            <QRCode
              id="qr-code"
              value={qrString}
              size={256}
              includeMargin={true}
            />
          </Grid>
          <Grid item xs={12} container alignItems="center" justify="center">
            <Grid item>
              <Button onClick={downloadQR}>
                <DownloadIcon className={classes.saveQRIcon} />
                {intl.formatMessage({ id: 'qr.modal.submit' })}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

QRCodeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  qrString: PropTypes.string,
}

export default withWidth()(QRCodeDialog)
