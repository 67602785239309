import React, { useState } from 'react'
import List from '@material-ui/core/List'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import WalletButton from '../../../material/button'
import accountsStore from '../../../stores/accounts-store'
import Wallet from '../components/wallet'
import { useObserver } from 'mobx-react-lite'

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing.unit,
    fontFamily: theme.typography.titleFont,
  },
  list: {
    marginBottom: `${theme.spacing.unit * 3}px`,
    marginTop: `${theme.spacing.unit * 2}px`,
    borderRadius: 4,
  },
  actions: {
    textAlign: 'center',
    margin: `${theme.spacing.unit * 4}px auto 0`,
  },
  button: {
    maxWidth: 320,
    width: '100%',
  },
  or: {
    margin: `${theme.spacing.unit}px 0`,
  },
}))

const NonCustodial = () => {
  const classes = useStyles()
  const accounts = React.useContext(accountsStore)
  const [error, setError] = useState('')
  const [password, setPassword] = useState()
  const [busy, setBusy] = React.useState(false)
  const intl = useIntl()

  const [selectedWallet, setWallet] = React.useState(
    accounts.accounts && accounts.accounts[0] && accounts.accounts[0],
  )
  const passRef = React.useRef(null)

  React.useEffect(() => {
    !selectedWallet &&
      setWallet(
        accounts.accounts && accounts.accounts[0] && accounts.accounts[0],
      )
    setPassword()
  }, [selectedWallet]) // eslint-disable-line

  React.useEffect(() => {
    setError('')
  }, [password]) // eslint-disable-line

  const hasWallets = accounts.accounts && accounts.accounts.length > 0
  const title = intl.formatMessage({
    id: `sign.in.access.${!hasWallets ? 'noAccounts' : 'previousAccounts'}`,
  })

  const handleDelete = ({ id }) => {
    if (id === selectedWallet?.id) setWallet()
    accounts.deleteAccount(id)
  }

  const handleContinueClick = async () => {
    if (selectedWallet?.id === null) {
      setError('sign.in.access.error.account')
      return
    }

    if (!password || password.length === 0) {
      setError('sign.in.access.error.password')
      return
    }

    try {
      setBusy(true)
      await accounts.login(password, selectedWallet.id)
    } catch (error) {
      setError(error.message)
    } finally {
      setBusy(false)
    }
  }

  const handleKeyUp = e => e.keyCode === 13 && handleContinueClick()

  const walletClickHandler = () => passRef.current.focus()

  return useObserver(() => (
    <>
      <Typography variant="subtitle1" align="center">
        {title}
      </Typography>
      {error && (
        <Typography color="error">
          {intl.formatMessage({ id: error })}
        </Typography>
      )}
      {hasWallets && (
        <>
          <List component="nav" className={classes.list} dense disablePadding>
            {accounts.accounts.map(wallet => (
              <Wallet
                key={wallet.id}
                wallet={wallet}
                setWallet={setWallet}
                selectedWalletId={selectedWallet?.id}
                onDelete={handleDelete}
                onClick={walletClickHandler}
              />
            ))}
          </List>
          <TextField
            variant="filled"
            label={intl.formatMessage({ id: 'general.password' })}
            name="password"
            fullWidth
            type="password"
            value={password || ''}
            onChange={({ target: { value } }) => setPassword(value)}
            inputRef={passRef}
            onKeyUp={handleKeyUp}
            autoFocus
          />
        </>
      )}
      <div className={classes.actions}>
        {hasWallets ? (
          <WalletButton
            className={classes.button}
            busy={busy}
            onClick={handleContinueClick}
          >
            {intl.formatMessage({ id: 'sign.in.access.submitText' })}
          </WalletButton>
        ) : (
          <WalletButton
            className={classes.button}
            component={Link}
            to={{ pathname: '/create-account/', state: { nonCustodial: true } }}
          >
            {intl.formatMessage({ id: 'sign.in.access.createNew' })}
          </WalletButton>
        )}
        <Typography align="center" className={classes.or}>
          {intl.formatMessage({ id: 'general.or' }).toUpperCase()}
        </Typography>
        <WalletButton
          className={classes.button}
          component={Link}
          to="/import-account"
          variant="outlined"
        >
          {intl.formatMessage({ id: 'sign.in.access.importAccount' })}
        </WalletButton>
      </div>
    </>
  ))
}

export default NonCustodial
