import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useObserver } from 'mobx-react-lite'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { useIntl } from 'react-intl'
import accountsStore from '../../stores/accounts-store'
import { ReactComponent as LogoIcon } from '../../resources/icons/logo.svg'
import Profile from './components/profile/profile'
import ProfileDrawer from './components/profile/profile-drawer'
import EnvirotnmentTopBar from '../../common/envitonment-top-bar/environment-top-bar'
import Config from '../../config'
import WalletButton from '../../material/button'
import { ReactComponent as QrCode } from '../../resources/icons/QrCode.svg'
import QRScannerDialog from '../../common/qr-scanner-dialog/qr-scanner-dialog'
import QRHandler from '../../common/qr-handler/qr-handler'

const useStyles = makeStyles(theme => ({
  toolbar: {
    background: '#fff',
    borderBottom: '1px solid #bdcbd9',
    color: '#0C2239',
    minHeight: '48px',
    padding: `0 ${theme.spacing.unit * 2}px`,
  },
  logoWrapper: {
    position: 'absolute',
    top: 6,
    left: 'calc(50% - 45px)',
    width: 90,
    '& svg': {
      height: 29,
      [theme.breakpoints.down(700)]: {
        height: 25,
      },
    },
    [theme.breakpoints.down('sm')]: {
      left: 16,
    },
  },
  logo: {
    height: '100%',
    width: '100%',
  },
  link: {
    [theme.breakpoints.down(700)]: {
      fontSize: '0.9rem',
    },
  },
  logoText: {
    fontSize: 11,
    textAlign: 'center',
    color: '#0C2239',
    fontWeight: 'bold',
    letterSpacing: 3,
  },
  button: {
    minWidth: '20px',
  },
}))

const Header = () => {
  const accounts = React.useContext(accountsStore)
  const classes = useStyles()
  const [showQRScanner, setShowQRScanner] = React.useState(false)
  const [qrString, setQRString] = React.useState()
  const intl = useIntl()

  const profileItems = React.useMemo(
    () => [
      {
        title: 'header.profile.details',
        action: '/account-details',
      },
      {
        title: 'header.profile.logOut',
        action: accounts.logOut,
      },
    ],
    [], // eslint-disable-line
  )

  const handleQRScan = data => {
    setShowQRScanner(false)
    setQRString(data)
  }

  const handleQRProcessingCompletion = () => {
    setQRString(null)
  }

  const handleSSOClick = () => {
    setShowQRScanner(true)
  }

  const handleCloseQRScannerDialog = () => {
    setShowQRScanner(false)
  }

  return useObserver(() => (
    <>
      {Config.environmentTopBar && (
        <EnvirotnmentTopBar {...Config.environmentTopBar} />
      )}
      <AppBar position="static" color="default">
        <Toolbar className={classes.toolbar}>
          <a className={classes.logoWrapper} href="https://corite.com">
            <LogoIcon className={classes.logo} />
          </a>
          {!accounts.current ? (
            <Grid
              container
              spacing={16}
              wrap="nowrap"
              justify="flex-end"
              alignItems="center"
            >
              {/*<Hidden xsDown>*/}
              {/*  <MenuInline routes={publicRoutes} />*/}
              {/*</Hidden>*/}
              {/*<Hidden smUp>*/}
              {/*  <Grid item>*/}
              {/*    <MenuDrawer routes={publicRoutes} />*/}
              {/*  </Grid>*/}
              {/*</Hidden>*/}
            </Grid>
          ) : (
            <Grid container spacing={8} justify="flex-end" alignItems="center">
              <Hidden smDown>
                <Grid item>
                  <WalletButton
                    color="secondary"
                    variant="text"
                    className={classes.button}
                    onClick={handleSSOClick}
                  >
                    <QrCode height={20} width={20} />
                  </WalletButton>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid item>
                  <WalletButton
                    className={classes.button}
                    color="secondary"
                    variant="text"
                    onClick={handleSSOClick}
                  >
                    <QrCode height={20} width={20} />
                  </WalletButton>
                </Grid>
              </Hidden>
              <Hidden xsDown>
                <Grid item>
                  <Profile name={accounts.current.name} items={profileItems} />
                </Grid>
              </Hidden>
              <Hidden smUp>
                <Grid item>
                  <ProfileDrawer
                    name={accounts.current.name}
                    items={profileItems}
                  />
                </Grid>
              </Hidden>
              {/*<Grid item>*/}
              {/*  <LanguageSelector />*/}
              {/*</Grid>*/}
            </Grid>
          )}
        </Toolbar>
        <QRScannerDialog
          open={showQRScanner}
          title={intl.formatMessage({ id: 'header.scanQrCode' })}
          onClose={handleCloseQRScannerDialog}
          onSuccess={handleQRScan}
        />
        {qrString && (
          <QRHandler
            qrString={qrString}
            onSuccess={handleQRProcessingCompletion}
            onFailure={handleQRProcessingCompletion}
          />
        )}
      </AppBar>
    </>
  ))
}

export default Header
