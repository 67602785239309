import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { useIntl } from 'react-intl'
import { resetPassword, verifyEmail } from '../../api/api'
import { useQueryParam } from '../../hooks/use-query-params'
import { generateUserData } from '../../lib/utils'
import {
  equalValues,
  minLength,
  required,
  validate,
} from '../../lib/validations'
import WalletButton from '../../material/button'

import { makeStyles } from '@material-ui/styles'
import { Link } from 'react-router-dom'
import BackButton from '../../material/BackButton'

const useStyles = makeStyles(theme => ({
  innerWrapper: {
    marginTop: theme.spacing.unit,
    position: 'relative',
    maxWidth: 600,
    margin: 'auto',
  },
  title: {
    fontFamily: theme.typography.titleFont,
  },
  subtitle: {
    marginBottom: theme.spacing.unit * 1,
  },
  actions: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
  },
  link: {
    marginTop: '20px',
    color: '#738aa4',
    display: 'inline-block',

    '&:hover': {
      textDecoration: 'none',
    },
  },
}))

const ResetPassword = () => {
  const email = useQueryParam('email')
  const token = useQueryParam('idpMessage')
  const classes = useStyles()
  const [busy, setBusy] = React.useState(false)
  const [error, setError] = React.useState('')
  const [mailSent, setMailSent] = React.useState(false)
  const intl = useIntl()
  const [account, setAccount] = useState({
    email,
    token,
    password: '',
    confirmPassword: '',
  })
  const handleTextFieldChange = ({ target: { name, value } }) => {
    setAccount(account => ({ ...account, [name]: value }))
    setError('')
  }

  const handleContinueClick = async e => {
    e.preventDefault()
    const { email, password, confirmPassword, token } = account

    if (
      !validate(
        [
          [token, [required('sign.up.error.token')]],
          [
            password,
            [
              required('sign.up.error.password'),
              minLength(6, 'sign.up.error.minPassword'),
            ],
          ],
          [confirmPassword, [equalValues(password, 'sign.up.error.verify')]],
        ],
        setError,
      )
    ) {
      return
    }
    setBusy(true)
    const { encMsg } = generateUserData(email, password)
    const response = await resetPassword({
      name: email,
      encMsg,
      identityProviderMsg: token,
    })
    setBusy(false)
    if (!response.ok) {
      return setError('Something went wrong')
    }
    const data = await response.json()
    console.log('data = ', data)
  }

  const sendVerification = async () => {
    try {
      setBusy(true)
      const response = await verifyEmail(account.email, 'reset-password')
      if (!response.ok) {
        return setError('Something went wrong')
      } else {
        setMailSent(true)
        setError('')
      }
    } catch (err) {
      console.log('err = ', err)
    } finally {
      setBusy(false)
    }
  }

  return (
    <div className={classes.innerWrapper}>
      <form onSubmit={handleContinueClick}>
        <Typography variant="h3" paragraph className={classes.title}>
          {intl.formatMessage({ id: 'reset.title' })}
        </Typography>
        {error && (
          <Typography color="error">
            {intl.formatMessage({ id: error })}
          </Typography>
        )}

        <Typography variant="subtitle1" className={classes.subtitle}>
          {intl.formatMessage({ id: 'reset.subtitle' }, { email })}
        </Typography>
        <Grid
          item
          container
          direction="column"
          spacing={24}
          className={classes.fields}
        >
          <Grid item xs>
            <TextField
              variant="filled"
              label="Email"
              name="email"
              fullWidth
              value={account.email || ''}
              onChange={handleTextFieldChange}
              autoFocus
            />
          </Grid>
          {mailSent && (
            <Grid item xs>
              <TextField
                variant="filled"
                label="Confirmation Code"
                name="token"
                fullWidth
                value={account.token || ''}
                onChange={handleTextFieldChange}
              />
            </Grid>
          )}
          {account.token && (
            <>
              <Grid item xs>
                <TextField
                  variant="filled"
                  label={intl.formatMessage({ id: 'reset.password' })}
                  type="password"
                  name="password"
                  fullWidth
                  value={account.password}
                  onChange={handleTextFieldChange}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  variant="filled"
                  label={intl.formatMessage({ id: 'reset.confirmPassword' })}
                  type="password"
                  fullWidth
                  name="confirmPassword"
                  value={account.confirmPassword}
                  onChange={handleTextFieldChange}
                />
              </Grid>
            </>
          )}
        </Grid>
        <div className={classes.actions}>
          {account.token ? (
            <WalletButton
              type="submit"
              busy={busy}
              disabled={
                busy ||
                !account.password ||
                !account.confirmPassword ||
                account.password !== account.confirmPassword
              }
            >
              {intl.formatMessage({ id: 'reset.button' })}
            </WalletButton>
          ) : (
            <WalletButton
              busy={busy}
              disabled={busy || !account.email}
              onClick={sendVerification}
            >
              {intl.formatMessage({ id: 'Send' })}
            </WalletButton>
          )}
        </div>
      </form>
      <Link to="/" className={classes.link}>
        <BackButton className={classes.back} />
      </Link>
    </div>
  )
}

export default ResetPassword
