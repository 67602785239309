import React from 'react'
/* import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs' */
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { FormattedMessage /* , useIntl  */ } from 'react-intl'
/* import useLocalStorage from '../../hooks/local-storage'
import Custodial from './components/custodial' */
import NonCustodial from './components/non-custodial'
import { useObserver } from 'mobx-react-lite'

const useStyles = makeStyles(theme => ({
  wrapper: {
    maxWidth: 600,
    margin: 'auto',
    marginTop: theme.spacing.unit,
  },
  title: {
    fontFamily: theme.typography.titleFont,
  },
  list: {
    marginBottom: `${theme.spacing.unit * 3}px`,
    marginTop: `${theme.spacing.unit * 6}px`,
    borderRadius: 4,
    border: `1px solid ${theme.palette.borders}`,
  },
  actions: {
    textAlign: 'center',
    margin: `${theme.spacing.unit * 4}px auto 0`,
  },
  button: {
    maxWidth: 320,
    width: '100%',
  },
  or: {
    margin: `${theme.spacing.unit}px 0`,
  },
  innerWrapper: {
    marginTop: theme.spacing.unit * 4,
    position: 'relative',
    maxWidth: 600,
    margin: 'auto',
  },
}))

const SignIn = () => {
  const classes = useStyles()
  /*  const intl = useIntl()
  const [tab, setTab] = useLocalStorage('wallet-sign-in-tab', 'custodial') */

  return useObserver(() => (
    <div className={classes.wrapper}>
      <FormattedMessage id="sign.in.access.account">
        {text => (
          <Typography variant="h3" paragraph className={classes.title}>
            {text}
          </Typography>
        )}
      </FormattedMessage>
      {/*  <Tabs
        value={tab}
        textColor="secondary"
        indicatorColor="secondary"
        centered
        variant="fullWidth"
      >
        <Tab
          label={intl.formatMessage({ id: 'sign.in.tabs.custodial' })}
          value="custodial"
          onClick={() => setTab('custodial')}
        />
        <Tab
          label={intl.formatMessage({ id: 'sign.in.tabs.non-custodial' })}
          value="non-custodial"
          onClick={() => setTab('non-custodial')}
        />
      </Tabs> */}
      <div className={classes.innerWrapper}>
        {/*  {tab === 'custodial' && <Custodial />}
        {tab === 'non-custodial' && <NonCustodial />} */}
        <NonCustodial />
      </div>
    </div>
  ))
}

export default SignIn
