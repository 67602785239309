import React from 'react'
import Card from '@material-ui/core/Card'
import LinearProgress from '@material-ui/core/LinearProgress'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/styles/makeStyles'
import { useObserver } from 'mobx-react-lite'
import EditIcon from '@material-ui/icons/Edit'
import AppImage from './app-image'
import { ReactComponent as ExternalLink } from '../../../../resources/icons/ExternalLink.svg'
import IconButton from '@material-ui/core/IconButton'
import DirectoryService from '../../../../lib/directory-service'

const useStyles = makeStyles(theme => ({
  card: {
    border: `1px solid ${theme.palette.borders}`,
    boxSizing: 'border-box',
    borderRadius: 5,
    position: 'relative',
    '&:hover': {
      filter: 'brightness(95%)',
      '& $cardAction': {
        display: 'block',
      },
    },
  },
  title: {
    textAlign: 'center',
    fontSize: 21,
    height: 31,
  },
  subTitle: {
    textAlign: 'center',
    fontSize: 14,
    opacity: 0.5,
  },
  description: {
    fontSize: '0.75rem',
  },
  balance: {},
  namePlaceholder: {
    height: 20,
    width: '50%',
    marginBottom: '0.35em',
  },
  descriptionPlaceholder: {
    '& div': {
      height: 10,
      width: '100%',
      marginTop: 5,
    },
  },
  cardAction: {
    position: 'absolute',
    display: 'none',
  },
  appImage: {
    margin: '40px auto 9px',
  },
}))

const AccountCard = ({ account, onEditClick }) => {
  const classes = useStyles()
  const dappConfig = new DirectoryService().getChainConfig(account.dapp.id)
  const dappIcon = dappConfig?.appIcon || account.dapp.appIcon

  return useObserver(() => (
    <Card className={classes.card}>
      {!account.id && account.dapp.custom && (
        <IconButton
          className={classes.cardAction}
          style={{ top: 2, right: 2 }}
          onClick={e => {
            e.preventDefault()
            onEditClick()
          }}
        >
          <EditIcon />
        </IconButton>
      )}
      {!account.dapp.isDefaultChain && account.dapp.url && account.id && (
        <a
          className={classes.cardAction}
          style={{ top: 10, right: 10 }}
          href={account.dapp.url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => {
            e.stopPropagation()
          }}
        >
          <ExternalLink />
        </a>
      )}
      {account.dapp.isLoading && <LinearProgress style={{ height: 2 }} />}
      <AppImage
        appId={account.dapp && account.dapp.id}
        appIcon={dappIcon}
        userId={account && account.id}
        appSize={115}
        userSize={45}
        className={classes.appImage}
      />
      <CardContent>
        {account.dapp.isInitialLoad ? (
          <div />
        ) : (
          <Typography className={classes.title} component="p">
            {account.dapp.name}
          </Typography>
        )}
        {account.dapp.isInitialLoad ? (
          <div />
        ) : (
          <Typography className={classes.subTitle} component="p">
            Corite
          </Typography>
        )}
      </CardContent>
    </Card>
  ))
}

export default AccountCard
