import React, { useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
//import CustodialAccountForm from '../../common/account-form/custodial-account-form'
//import AccountType from '../../common/account-type/account-type'
import { useQueryParam } from '../../hooks/use-query-params'
import accountsStore from '../../stores/accounts-store'
import AccountForm from '../../common/account-form/account-form'
import ViewMnemonic from './components/view-mnemonic'
import VerifyMnemonic from './components/verify-mnemonic'
import { generateMnemonic, shuffle, split } from '../../lib/utils'
import { makeStyles } from '@material-ui/styles'
//import Config from '../../config'
//import { Link } from '@material-ui/core'

const Step = Object.freeze({
  // ...(Config.custodial && { AccountType: 'AccountType' }),
  Form: 'Form',
  ViewMnemonic: 'ViewMnemonic',
  VerifyMnemonic: 'VerifyMnemonic',
})
const useStyles = makeStyles(theme => ({
  innerWrapper: {
    position: 'relative',
    maxWidth: 950,
    margin: 'auto',
  },
  bottomWrapper: {
    maxWidth: 600,
    margin: 'auto',
  },
}))

const SignUp = () => {
  const email = useQueryParam('email')
  const token = useQueryParam('idpMessage')
  const nonCustodialQuery = useQueryParam('nonCustodial')
  const classes = useStyles()
  const accounts = React.useContext(accountsStore)
  const mnemonic = useRef(generateMnemonic())
  const location = useLocation()
  const nonCustodial =
    !!nonCustodialQuery || (location.state && location.state.nonCustodial)
  const [step, setStep] = useState(
    nonCustodial ? Step.Form : Object.keys(Step)[token ? 1 : 0],
  )
  const [account, setAccount] = useState(token ? { email, token } : null)
  // const [isCustodial, setIsCustodial] = useState(nonCustodial ? false : !!token)

  /*   const handleTypeContinue = async isCustodialWallet => {
    setIsCustodial(isCustodialWallet)
    setStep(Step.Form)
  } */

  const handleFormContinue = async account => {
    setAccount(account)
    setStep(Step.ViewMnemonic)
  }

  /*   const handleCustodialFormContinue = async ({
    keyPair,
    authData,
    email: mail,
  }) => {
    await accounts.custodialLogin({ keyPair, authData, email: mail })
  }
 */
  const handleViewMnemonicContinue = async () => {
    setStep(Step.VerifyMnemonic)
  }

  const handleVerifyMnemonicConfirm = async () => {
    await accounts.addAccountAndLogin(
      account.name,
      mnemonic.current,
      account.password,
    )
  }

  /*   const stepBack = () => {
    setStep(Step.AccountType)
  } */
  return (
    <div className={classes.innerWrapper}>
      {/*    {step === Step.AccountType && (
        <AccountType onContinue={handleTypeContinue} />
      )}
      {step === Step.Form && Config.custodial && isCustodial && (
        <CustodialAccountForm
          onContinue={handleCustodialFormContinue}
          initialAccount={account}
        />
      )} */}
      {step === Step.Form && <AccountForm onContinue={handleFormContinue} />}
      {step === Step.ViewMnemonic && (
        <ViewMnemonic
          mnemonic={mnemonic.current}
          onContinue={handleViewMnemonicContinue}
        />
      )}
      {step === Step.VerifyMnemonic && (
        <VerifyMnemonic
          mnemonic={mnemonic.current}
          mnemonicComponents={shuffle(split(mnemonic.current))}
          onConfirm={handleVerifyMnemonicConfirm}
        />
      )}
      {
        <div className={classes.bottomWrapper}>
          {/*{step !== Step.AccountType && <BackButton onClick={stepBack} />}*/}
          {/* {step !== Step.AccountType && (
          <Link className={classes.text} onClick={stepBack}>
            Change wallet type
          </Link>
        )} */}
        </div>
      }
    </div>
  )
}

export default SignUp
