import { createMuiTheme } from '@material-ui/core'
import getPalette from './palette'

const getTheme = (type = 'dark', { primary, secondary, error, info }) =>
  createMuiTheme({
    typography: {
      useNextVariants: true,
      titleFont: '"Montserrat", "Verdana", "Tahoma", sans-serif',
      fontFamily: '"Open Sans", "Tahoma", "Arial", sans-serif;',
      h3: {
        fontSize: '18px',
        lineHeight: '25px',
        fontWeight: 700,
        textTransform: 'uppercase',
        color: secondary.main,
      },
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    palette: {
      primary,
      secondary,
      error,
      info,
      type,
      borders: type === 'dark' ? '#eeeeee' : '#BDCBD9',
      background: {
        paper: type === 'dark' ? '#030A11' : '#f6f8fb',
        default: type === 'dark' ? '#030A11' : '#f6f8fb',
        contrast: type === 'dark' ? '#2E2E2E' : 'white',
        secondary: type === 'dark' ? '#2F4968' : '#f6f8fb',
        confirmationBg: type === 'dark' ? '#3d3d3d' : '#F2F2F2',
        // headerBg: type === 'dark' ? '#3d3d3d' : '#F2F2F2',
      },
    },
    overrides: {
      MuiChip: {
        colorSecondary: {
          height: 22,
        },
        iconColorSecondary: {
          height: 20,
          width: 20,
          marginLeft: 0,
          fontSize: '0.8rem',
          color: secondary.main,
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
        },
        deleteIconColorSecondary: {
          width: 17,
        },
      },
      MuiTableCell: {
        root: {
          borderBottom: 'none',
          '@media (max-width: 600px)': {
            padding: '4px 8px 4px 8px',
          },
        },
      },
      MuiLinearProgress: {
        root: {
          height: 6,
          borderRadius: 6,
        },
        barColorPrimary: {
          backgroundColor: info.main,
        },
        determinate: {
          backgroundColor: '#2d2d2d',
        },
      },
      MuiTableHead: {
        root: {
          borderBottom: '1px solid rgba(81, 81, 81, 1)',
        },
      },
      MuiTablePagination: {
        root: {
          borderTop: '1px solid rgba(81, 81, 81, 1)',
        },
        caption: {
          '@media (max-width: 600px)': {
            display: 'none',
          },
        },
        selectRoot: {
          '@media (max-width: 600px)': {
            marginRight: 8,
          },
        },
      },
      MuiLink: {
        root: {
          color: secondary.main,

          '&:hover': {
            cursor: 'pointer',
          },
          '&.active': {
            color: primary.main,
          },
        },
      },
      MuiTabs: {
        root: {
          minHeight: 50,
          borderBottom: '1px solid #BDCBD9',
          '& button': {
            minHeight: 50,
          },
        },
      },
      MuiTab: {
        root: {
          backgroundColor: '#E4EAEF',
          flexBasis: '50%',
        },
        labelContainer: {
          padding: '6px',
        },
        label: {
          color: secondary.dark,
          fontSize: '16px',
          lineHeight: '20px',
          fontWeight: 400,
          fontFamily: '"Montserrat", "Verdana", "Tahoma", sans-serif',
          textTransform: 'capitalize',
        },
      },
      MuiButton: {
        contained: {
          boxShadow: 'inset 0 -2px 0 0 #bdcbd9',
        },
        containedPrimary: {
          boxShadow: 'inset 0 -2px 0 0 #d20032',
        },
        containedSecondary: {
          color: secondary.main,
          backgroundColor: '#fff',

          '&:hover': {
            color: secondary.dark,
            backgroundColor: 'inherit',
          },
        },
        outlinedPrimary: {
          borderWidth: '2px',
          borderColor: primary.main,

          '&:hover': {
            borderWidth: '2px',
          },
        },
        textSecondary: {
          '&:hover': {
            color: secondary.dark,
            backgroundColor: secondary.light,
          },
        },
        sizeLarge: {
          padding: '8px',
        },
      },
      MuiAppBar: {
        root: {
          boxShadow: 'none',
        },
      },
      MuiListItem: {
        root: {
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',

          '&$selected, &$selected:hover, &$selected:focus': {
            backgroundColor: 'rgba(0,0,0,.03)',
            paddingTop: '12px',
            paddingBottom: '11px',
          },
        },
      },
      MuiListItemText: {
        primary: {
          fontSize: '1.1rem',
        },
      },
      MuiTypography: {
        root: {
          '&.opacity': {
            opacity: 0.5,
          },
          '&.opacity7': {
            opacity: 0.7,
          },
        },
      },
      MuiInputBase: {
        root: {
          backgroundColor: 'transparent',
          color: secondary.dark,
        },
      },
      MuiFilledInput: {
        root: {
          backgroundColor: '#eef2f6',

          '&:hover': {
            backgroundColor: '#eef2f6',
          },
          '&$focused': {
            backgroundColor: '#eef2f6',
          },
          '&$disabled': {
            backgroundColor: 'rgba(0,0,0,.03)',
          },
        },
        input: {
          padding: '28px 12px 15px',
        },
        underline: {
          '&:before': {
            borderBottom: '1px solid #BDCBD9',
          },
          '&:after': {
            borderBottom: '2px solid #BDCBD9',
          },
          '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: '2px solid #BDCBD9',
          },
          '&$focused:after': {
            borderColor: '#738AA4',
          },
          '&$focused': {
            backgroundColor: '#eef2f6',
          },
          '&$disabled:before': {
            borderBottom: 'none',
          },
        },
      },
      MuiInputLabel: {
        formControl: {
          top: '3px',
        },
      },
      MuiFormLabel: {
        root: {
          color: secondary.darkText,
          fontFamily: '"Montserrat", "Verdana", "Tahoma", sans-serif',

          '&$focused': {
            color: secondary.lightText,
          },
          '&$disabled': {
            color: secondary.lightText,
          },
        },
      },
      MuiSelect: {
        select: {
          '&:focus': {
            backgroundColor: '#eef2f6',
          },
        },
      },
      MuiPaper: {
        elevation8: {
          marginTop: '63px',
        },
      },
    },
  })

export default function(type) {
  const palette = getPalette(type)
  const theme = getTheme(type, palette)

  return { ...theme }
}
