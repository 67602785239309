module.exports = {
  explorerUrl: 'https://dev.explorer.chromia-development.com',
  canAddDapp: false,
  custodial: false,
  environmentTopBar: {
    message: 'Pre-Production Environment',
    textColor: '#CB92F0',
    backgroundColor: '#434343',
  },
  crossChainTransfersEnabled: false,
  chainId: 'F7652A6890C094F1830D564BCED558EF401B6AACF787EBA6EEC0FC64EEA9F521',
  OUT_PUBLIC_KEY:
    '02d32eb147f1650d415e306d10a061c23d56d9241523734eca6dbac60fbe4059cb',
  IN_PUBLIC_KEY:
    '0355e239f2666877c93767863d368f8a3a999ad2ddd06b41ee4c173cd9a7ed44c8',
  CUSTODIAL_WALLET_ADDRESS: 'https://custodial-wallet.chromia.dev',
  SIGNATURE_API_ADDRESS: 'https://postchain-proxy.preprod.corite.com',
  IDP_ADDRESS: 'https://idp.chromia.dev',
  chains: [
    {
      rid: 'F7652A6890C094F1830D564BCED558EF401B6AACF787EBA6EEC0FC64EEA9F521',
      url: 'https://dev.vault-node.chromia-development.com',
    },
    // Corite Pre-production
    {
      rid: process.env.REACT_APP_CORITE_RID,
      url: 'https://postchain.preprod.corite.com',
      appIcon: '/corite.svg',
    },
  ],
  dapps: [
    {
      name: 'Corite',
      id: process.env.REACT_APP_CORITE_RID,
      url: 'https://preprod.corite.com/wallets?connect=crypto',
      appIcon: '/corite.svg',
    },
  ],
}
