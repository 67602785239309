import React, { useContext } from 'react'
import accountsStore from '../../stores/accounts-store'
import AccountCard from './components/app-card/account-card'
import Grid from '@material-ui/core/Grid'
import { useObserver } from 'mobx-react-lite'
import CenterComponent from '../../common/center-component/center-component'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'left',
    paddingBottom: 20,
    marginTop: theme.spacing.unit,
    fontFamily: theme.typography.titleFont,
  },
}))

const LinkedApps = ({ history }) => {
  const classes = useStyles()
  const accounts = useContext(accountsStore)

  return useObserver(() => (
    <>
      <Typography variant="h3" paragraph className={classes.title}>
        Linked Apps
      </Typography>
      {accounts.current.dappAccounts.isLoading ? (
        <CenterComponent>
          <CircularProgress />
        </CenterComponent>
      ) : (
        <>
          {accounts.current.dappAccounts.accounts.length > 0 ? (
            <Grid container spacing={16}>
              {accounts.current.dappAccounts.accounts.map(account => (
                <Grid item key={account.id}>
                  <AccountCard account={account} history={history} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <CenterComponent>
              <Typography variant="subtitle1">
                You have no linked Apps
              </Typography>
            </CenterComponent>
          )}
        </>
      )}
    </>
  ))
}

export default LinkedApps
